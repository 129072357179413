import React, {
  Fragment,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import clsx from 'clsx';

import { ButtonProps } from '../button/button';
import styles from './tab.module.scss';

interface Pane {
  title: ReactNode | string;
  content?: ReactNode | string;
  key: number;
  to?: string;
  disabled?: boolean;
}

interface TabProps {
  panes: Pane[];
  contentClassName?: string;
  tabBarClassName?: string;
  onChange?: (key: number) => void;
  activeTab?: number;
  extraContent?: ReactElement;
  buttonProps?: Omit<ButtonProps, 'children'>;
  inBetweenTabAndContent?: ReactElement;
  tabsButtonGutter?: 'gutter-s' | 'gutter-m';
  hasContent?: boolean;
  tabButtonComponent?: any;
  customContent?: any;
}

export const Tab = ({
  panes,
  contentClassName,
  tabBarClassName,
  onChange,

  customContent,
  extraContent,
  buttonProps = {
    variant: 'text',
    color: 'secondary',
    size: 's',
  },
  activeTab = customContent ? undefined : 0,
  tabButtonComponent,
  inBetweenTabAndContent,
  tabsButtonGutter = 'gutter-m',
  hasContent = true,
}: TabProps) => {
  const [value, setValue] = useState<number>();

  useEffect(() => {
    setValue(activeTab);
  }, [activeTab]);

  const handleChange = (newValue: number) => {
    setValue(newValue);
    if (value !== newValue) {
      onChange && onChange(newValue);
    }
  };

  return (
    <>
      <div className={clsx(styles.tabBar, tabBarClassName)}>
        <div className={styles.tabs}>
          {panes.map((item) => {
            const activeTab = value === item.key;
            const Button = tabButtonComponent ?? 'button';
            return (
              <Button
                // type={'button'}
                key={item.key}
                to={item.to}
                disabled={item.disabled}
                className={clsx(
                  styles.tabButton,
                  styles[tabsButtonGutter],
                  !customContent && activeTab && styles.active
                )}
                onClick={() => handleChange(item.key)}
                {...buttonProps}
              >
                {item.title}
              </Button>
            );
          })}
        </div>
        {extraContent ? (
          <div className={styles.extraContent}>{extraContent}</div>
        ) : null}
      </div>

      {inBetweenTabAndContent}
      {hasContent ? (
        <div className={clsx(styles.content, contentClassName)}>
          {customContent ??
            panes.map((item) => (
              <Fragment key={item.key}>
                {value === item.key ? item.content : null}
              </Fragment>
            ))}
        </div>
      ) : null}
    </>
  );
};
